import React from 'react';
import { OpenSansParagraph, RecoletaHeading } from './common/typography';
import DoctorDemo from '../assets/images/doctordemo/doctor-demo.png';
import AppStoreButton from './common/appbuttons/AppStoreButton';
import GooglePlayStoreButton from './common/appbuttons/GooglePlayStoreButton';
import { AppleStore, GooglePlayStore } from '../utils/constants';
import Container from './Reimbursement/Container';
import { useIntl } from 'react-intl';

const QRCodeSection = () => {
  const intl = useIntl();

  const germanStrings = {
    header: 'Cara Care für Reizdarm Demo-Version',
    description:
      'Um die Demo-Version zu nutzen, müssen Sie zunächst die App Cara Care installieren. Bitte scannen Sie hierfür diesen QR Code oder wählen sie den passenden Store (App Store bzw. Google Play Store) manuell für den Download aus.',
    text:
      'Um nach der Installation die Demo-Version zu erstellen, bitten wir Sie den in Ihrer Mail enthaltenen QR-Code mit Ihrem Smartphone erneut zu scannen, bzw. den enthaltenen Link auf Ihrem Smartphone zu öffnen. Wählen Sie anschließend die Option "Einen Demo-Account erstellen" aus um den Account zu aktivieren. Dies kann bis zu einer Minute dauern. Wichtiger Hinweis: Innerhalb der Demo-Version werden Demo-Tracking-Daten eines fiktiven Patienten sowie das vollständige Programm inklusive aller potentiell verfügbaren Inhalte bereitgestellt. Die Demo-Version von Cara Care für Reizdarm dient ausschließlich Angehörigen von Fachkreisen zu Demonstrationszwecken und nicht zur Therapie des Reizdarmsyndroms. Jeder Code kann nur einmal auf einem Gerät verwendet werden.',
  };

  const englishStrings = {
    header: 'Cara Care for IBS Demo-Version',
    description:
      'To use the demo version, you must first install the Cara Care app. Please scan this QR code or manually select the appropriate store (App Store or Google Play Store) for download.',
    text:
      'After installation, to create the demo version, please scan the QR code contained in your email again with your smartphone or open the link contained on your smartphone. Then select the option "Create a demo account" to activate the account. This can take up to a minute. Important note: Within the demo version, demo tracking data of a fictitious patient as well as the complete program including all potentially available content are provided. The demo version of Cara Care for irritable bowel syndrome is intended exclusively for members of professional circles for demonstration purposes and not for the therapy of irritable bowel syndrome. Each code can only be used once on a device.',
  };

  const strings = intl.locale === 'de' ? germanStrings : englishStrings;

  return (
    <Container
      css={{
        paddingLeft: 0,
        paddingRight: 0,
        textAlign: 'center',
        paddingTop: '3rem',
        paddingBottom: '3rem',
        '@media (max-width: 768px)': {
          paddingLeft: '1rem',
          paddingRight: '1rem',
        },
      }}
    >
      <RecoletaHeading
        css={{
          fontSize: '1.5rem',
          marginBottom: '1rem',
        }}
      >
        {strings.header}
      </RecoletaHeading>
      <div
        css={{
          marginTop: '1rem',
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '1rem',
          '@media (max-width: 768px)': {
            flexDirection: 'column',
          },
        }}
      >
        <OpenSansParagraph>{strings.description}</OpenSansParagraph>
      </div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={DoctorDemo}
          alt="QR Code"
          css={{
            height: 100,
            width: 100,
          }}
        />
      </div>
      <OpenSansParagraph>OR</OpenSansParagraph>
      <div
        css={{
          marginTop: '1rem',
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '@media (max-width: 768px)': {
            flexDirection: 'column',
          },
        }}
      >
        <AppStoreButton
          href={AppleStore}
          css={{
            transition: 'all 0.3s ease',
          }}
        />
        <GooglePlayStoreButton
          href={GooglePlayStore}
          css={{
            transition: 'all 0.3s ease',
          }}
        />
      </div>
      <div
        css={{
          marginTop: '1rem',
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '@media (max-width: 768px)': {
            flexDirection: 'column',
          },
        }}
      >
        <OpenSansParagraph>{strings.text}</OpenSansParagraph>
      </div>
    </Container>
  );
};

export default QRCodeSection;
