import styled from '@emotion/styled';

const Container = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 672px;
`;

export default Container;
