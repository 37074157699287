import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

import { ContentfulHomepage } from '../graphql-types';
import QRCodeSection from '../components/QRCodeSection';
import Container from '../components/Homepage/Container';

interface OwnProps {
  pathContext: {
    locale: 'en' | 'de';
  };
  data: {
    homepage: ContentfulHomepage;
  };
}

type Props = OwnProps & WrappedComponentProps;

const Homepage: React.FC<Props> = ({ pathContext: { locale } }) => {
  return (
    <Layout noHeader noFooter>
      <Container
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <SEO title={'Doctors Demo'} lang={locale} noindex />
        <QRCodeSection />
      </Container>
    </Layout>
  );
};

export default injectIntl(Homepage);
